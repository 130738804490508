
/* .radio-tile-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
} */
.radio-tile-group .input-container {
    position: relative;    
    /* height: 5rem;
    width: 10.5rem;
    margin: 0.4rem; */
}
.radio-tile-group .input-container .radio-button {
    opacity: 0;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    margin: 0;
}

.radio-tile-group .input-container .radio-tile-label {
    text-align: center;
    font-size: 0.75rem;
    color: rgba(0,0,0,.87);
}



.dashboardPaper {
    border-radius: .5rem;
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.chartContainer svg {
    border-radius: 6px;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}

.chartLoader {
    width:100%;
    top: 45%;
    position: relative;
    padding: 1.5rem;
}

.circle {
    display: inline-table;
    vertical-align: middle;
    width: 3rem;
    background-color: #e9d3b7;
    border-radius: 1rem;
    position: absolute;
    margin-left: 8rem;
    margin-top: -3rem;
  }
  
  .circle__content {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
  }

  .radio-tile-group .input-container .radio-button:checked + .radio-tile > .circle {
      background-color: #f7b561 ;
  }
/* .chart {
    padding-right: '30px';
  } */

  .test div.MuiGrid-grid-sm-6:last-child {
    padding-top: 1rem;
    }
   .mobile-padding{
       padding-top: 1rem;
   }
  /* @media (min-width:600px)  { 

    .radio-tile-group .input-container .icon svg {
        fill: rgb(255, 153, 0);
        width: 3rem;
        height: 3rem;
    }

    .radio-tile-group .input-container {
        position: relative;
        height: 7rem;
        width: 40%;
        max-width: 10rem;
        margin: 0.5rem;
    }
    
    .test div.MuiGrid-grid-sm-6:last-child {
        padding-left: .5rem;
        padding-top: 0;
    }

    .test div.MuiGrid-grid-sm-6:first-child {
        padding-right: .5rem;
    }
    .showOnlyinMobile {
        display: none;
    }
    .mobile-padding{
        padding-top: 0;
    }
    .circle {
        margin-left: 7.5rem;
        margin-top: -5rem;
    }

} */

/* Common CSS */

.padding-1 {
    padding:1rem;
}

.radio-tile-group{
    padding-top :1rem;
}

.input-container {
    list-style-type: none;
    width: 33.3%;
    display: inline-block;
    padding: 0rem 1rem 1rem;
}

.radio-icon {
    border: 1px solid rgba(0,0,0,.12);
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    text-align: center;
}

.radio-tile-group .input-container .radio-tile {
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: transform 300ms ease;
}
.radio-tile-group .input-container .icon svg {
    fill: #1E2A38;
    width: 1.8rem;
    height: 1.8rem;
    margin-top: .5rem;
}

.radio-tile-group .input-container .radio-button:checked + .radio-tile .radio-icon {
    background: #00A4E1;
    transform: scale(1.1, 1.1);
    transition: transform 350ms ease;
}

.radio-tile-group .input-container .radio-button:checked + .radio-tile .radio-icon svg{
    fill: #ffffff;
    transform: scale(1.05, 1.05);
    transition: transform 350ms ease;
}

.radio-tile-group .input-container .radio-button:checked + .radio-tile .radio-tile-label {
    color: #00A4E1;
    font-weight: 700;
    transition: transform 350ms ease;
}

/* .highcharts-container {
    border-radius: 6px;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
    top:-2rem;
} */
.formButton svg{
    height: 2.5rem;
    fill : #00A4E1;
}

.formButton {
    max-width: 200px;
    display: inline-flex;
    padding: .5rem;
    border-radius: 5px;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
    color: #000000;
    text-align: center;
    font-weight: 600;
    background: rgb(255,255,255);   
    background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(210,248,255,1) 0%, rgba(255,255,255,1) 29%, rgba(255,255,255,1) 69%, rgba(255,255,255,1) 100%);
}

.formButton:hover {
    text-decoration: none;
}

@media (min-width:1280px)  {
    .formButton {
        width:200px;
    }
    .hideForDesktop {
        display: none !important;
    }
    .dashboard-form-icon-container svg{
        left: 35px !important;
    }
} 

.userActionContainer {
    padding: 1.5rem;
}

.userActionContainer p:nth-child(1) {
    font-size: 3rem;
    color:#fff;
    line-height: 2rem;
}

.userActionContainer p:nth-child(2) {
    font-size: 14px;
    font-weight:600;
    color: rgba(255,255,255,.87);
}

.overdue {
    background: #e76f51;
    border-top-left-radius: .5rem;
}

.dueToday {
    border-top-right-radius: .5rem;
    background: #f4a261;
}

.pending {
    border-bottom-left-radius: .5rem;
    background: #2F9EC2;
}

.completed {
    border-bottom-right-radius: .5rem;
    background: #60C2AC;
}

.userAction {
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    border-radius: .5rem;
}

.userAction a:hover{
    text-decoration: none;
}

.userAction > div:nth-child(1) {
    padding-right: .2rem;
    padding-bottom: .2rem;
}

.userAction > div:nth-child(3) {
    padding-right: .2rem;
}

.inductionContainer > div {
    padding: 0 .5rem;
}

.inductionContainer > div > a > div {
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    border-radius: .5rem;
    text-align: center;
    background: #fff;
}

.induction-icon{
    display: inline-block;
    background: rgb(0, 66, 218);
    height: 3.5rem;
    width: 3.5rem;
    border-radius: 50%;
}

.inductionContainer {
    padding-top: 1.5rem;
}

.induction-icon svg {
    position: relative;
    left: -22px;
    height: 2rem;
    top: 10px;
}

.inductionContainer > div > a:hover {
    text-decoration: none;
    color: rgba(0,0,0,.87);
}

.inductionContainer > div > a > div > div {
    position: relative;
    top: -25px;
}

.assigned  .induction-icon {
    background: #24BC26;
}

.inductionCompleted  .induction-icon {
    background: #BC9709;
}

.active > p:nth-child(1), .assigned > p, .inductionCompleted > p {
    top: -10px;
    position: relative;
    margin-bottom: 0;
}

.inductionContainer p {
    top: -10px;
    position: relative;
    margin-bottom: 0;
    font-size: .875rem;
}

.inductionNumber {
    font-size: 1.5rem;
    font-weight: 600;
}

.dashboardLabel {
    color: rgba(0,0,0,.87);
    font-size: 15px !important;
    padding: 1.5rem 0 1rem;
}

.fatigueContainer{
    border-radius: .5rem;
    padding: 1rem;
    /* background: #292934; */
    background: #495057;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    max-height: 16rem;
}

.sleepdetail > p {
    color: rgba(255,255,255,.65);
    font-size: 14px;
    font-weight: 600;
}

.fatigueContainer .highcharts-container{
    /* top:-225px !important; */
    top:-210px !important;
}

.userProfilePaper{
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.userProfileIcon, .certificateImage{
    width: 40px;
    text-align: center;
    display: inline-block;
    padding-right: 1rem;
    position: relative;
    top: -5px;
}

.certificateContent, .profileContent{
    display: inline-block;
    padding-left: .5rem;
    width:75%
}

.certificateContent {
    position: relative; 
    top:-5px;
}

.certificateContent > div:first-child , .profileContent > div:first-child {
    font-size: 14px;
    font-weight: 600;
}

.certificateContent > div:first-child, .profileContent > div:nth-child(2) {
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 250px;
    overflow: hidden;
}

.editIcon {
   float: right;
   position: relative;
   top:25%; 
}

.fatigueContainer rect.highcharts-background {
    /* fill:#292934; */
    fill:#495057;
}

.pieChartContainer rect.highcharts-background {
    /* fill:#292934; */
    fill:#dee2e6;
}

@media (min-width:481px)  { 
    .spacingDesktop {
        padding: 0 1.5rem;
    }
    .showForMobile{
        display: none!important;
    }
}

@media (max-width:480px)  { 
    .hideForMobile {
        display: none !important;
    }
}

.singleMobileBtn {
    background: #0077b6;
    min-height: 8rem;
    border-radius:.5rem;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.singleMobileBtn p {
    font-size: 14px;
    padding-top: 4px;
    color: rgba(255,255,255,.75);
}

.singleMobileBtn > div:nth-child(1) {
    text-align: center;
}

.singleMobileBtn > div:nth-child(2) {
    padding: 1.5rem 1rem 0;
}

.singleMobileBtn > div > svg {
    height: 6rem;
    position: relative;
    top: 15px;
    left: 2px;
}

.singleMobileBtn  button {
    text-transform: uppercase;
    padding: 0;
    height: 3rem;
    font-weight: 600;
    font-size: 15px;
    color: rgba(255,255,255)
}

.signedProjectTiles {
    background: #632626;
}

.fatigueContainer text{
    fill: rgba(255,255,255,.45) !important;
    font-weight:600
}

.appName:hover {
    text-decoration: none;
    color:rgb(238,238,238);
}

.dashboard-form-button {
    padding: 0 .5rem;
}

.dashboard-form-button a:hover{
    text-decoration: none;
}
.dashboard-form-name{
    text-align: center;
    padding: 6px 0 24px;
    color: rgba(0,0,0,.87);
    line-height: 15px !important;
}

.dashboard-form-icon-container{
    height: 65px;
    border-radius: 12px;
}

.form-link-container div:nth-child(2n) .dashboard-form-icon-container {
    background: #E9E4FA;
}

.form-link-container div:nth-child(2n) .dashboard-form-icon-container svg {
    fill: #7B6079;
}

.form-link-container div:nth-child(4n) .dashboard-form-icon-container {
    background: #FCF0DC;
}

.form-link-container div:nth-child(4n) .dashboard-form-icon-container svg {
    fill: #B98E6C;
}

.form-link-container div:nth-child(4n-1) .dashboard-form-icon-container {
    background: #E4F7FB;
}

.form-link-container div:nth-child(4n-1) .dashboard-form-icon-container svg {
    fill: #547e87;
}

.form-link-container div:nth-child(4n+1) .dashboard-form-icon-container {
    background: #FAE5E4;
}

.form-link-container div:nth-child(4n+1) .dashboard-form-icon-container svg {
    fill: #c47058;
}

.dashboard-form-icon-container svg{
    position: relative;
    text-align: center;
    width: 40px;
    height: 40px;
    left: 15px;
    top: 12px;
}

.scrolling-wrapper-flexbox {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
}

.scrolling-wrapper-flexbox {
    margin-bottom: 1rem;
    width: 87vw;
    -webkit-overflow-scrolling: touch;
}

.dashboard-project-card {
    width: 140px;
    border-radius: 12px;
    flex: 0 0 auto;
    margin-right: 10px;
    text-align: center;
    height: 120px;
}

.dashboard-project-card a:hover{
    text-decoration: none;
}

.scrolling-wrapper-flexbox ::-webkit-scrollbar {
    display: none;
}

.scrolling-wrapper-flexbox > div:nth-child(2n) {
    background: #1572A1;
}

.scrolling-wrapper-flexbox > div:nth-child(4n) {
    background: #655D8A;
}

.scrolling-wrapper-flexbox > div:nth-child(4n-1) {
    background: #876445;
}

.scrolling-wrapper-flexbox > div:nth-child(4n+1) {
    background: #632626;
}

.no-project-assigned {
    background: #FAE5E4;
    padding: 0.75rem;
    border-radius: 12px;
    color: #c47058;
}

.see-all-container {
    padding: 1.5rem 0 1rem;
}

.see-all-container a {
    text-decoration: none;
    font-size: 12px;
    background: rgb(220, 220, 220);
    color: #1E2A38;
    padding: 0.3rem 0.7rem;
    border-radius: 20px;
    font-weight: 600;
}

.see-all-container a svg {
    position: relative;
    top: -2px;
}

.dashboardHeader{
    font-size: 1.5rem;
    line-height: 3rem;
    font-style: italic;
    color: #1E2A38;
}

.resourceTile {
    display: flex;
    background-color: #fff;
    box-shadow: rgb(0 0 0 / 25%) 0px 14px 28px, rgb(0 0 0 / 22%) 0px 10px 10px;
    border-radius: 1rem;
    padding: 1rem;
}

.resourceTile > div:first-child {
    width:calc(100% - 120px);;
}
  
.resourceTile > div {
    width:120px;
}

.resourceTile > div:nth-child(2) {
    background: #eee;
    border-radius: 50%;
    padding: 0.75rem;
}

.lessonContainer{
    padding: 1rem;
    min-height:15rem;
}

.move-up{
    position: relative;
    top:-260px
}

.footer-move-up{
    position: relative;
    top:-267px;
}

@media (min-width:1280px){
    .lgDisplaySlider{
        display:block;
    }
    .displaySlider{
        display:none;
    }
    .fatigueContainer{
        max-height: 15.5rem;
    }
    .lessonContainer{
        min-height:18.2rem;
    }
    .lessonContainer .slick-slider.slick-initialized {
        height: auto;
        min-height: 200px;
        max-height: 370px;
        overflow-y: auto;
    }
    .singleMobileBtn{
        min-height: 15.5rem;
    }
    .singleMobileBtn > div:nth-child(2){
        padding: 5.5rem 1rem 0;
    }
    .singleMobileBtn > div > svg{
        height: 10rem;
        top: 30px;
    }
    .singleMobileBtn p {
        font-size:16px
    }
    .singleMobileBtn span {
        font-size: 18px;
    }
    /* .fatigueContainer .highcharts-container{
        top: -215px !important
    } */
    /* .move-up{
        top:-270px !important
    } */
}



.lessonContainer .slick-slider.slick-initialized {
    height: 150px;
    overflow-y: auto;
}

.lessonContainer .slick-slider::-webkit-scrollbar{
    height: 5px;
    width: 5px;
}

.dashboardCardShadow {
    border-radius: 1rem;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
}
.sliderArrow .MuiIconButton-root {
    padding: 0 !important;
}

.dashboardCardLoader{
    height: 25px !important;
    width: 25px !important;
}

.UserProfileLoader{
    display: flex;
    justify-content: center;
    width: 100%;
}