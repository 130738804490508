.card {
  margin-bottom: 2rem;
}
.datatable-filter .p-datatable-header {
  background-color: #233044;
  padding: 7px 7px;
}
.datatable-filter .p-button {
  background-color: white;
  color: royalblue;
}
/* .datatable-filter .p-paginator .p-paginator-current {
  margin-left: auto;
} */

.datatable-filter .table-header {
  display: flex;
  justify-content: space-between;
}

.datatable-filter .p-datatable.p-datatable-item-list .p-datatable-header {
  padding: 1rem;
  text-align: left;
  font-size: 1.5rem;
}

.datatable-filter .p-datatable.p-datatable-item-list .p-paginator {
  padding: 1rem;
}

.datatable-filter
  .p-datatable.p-datatable-item-list
  .p-datatable-thead
  > tr
  > th {
  text-align: left;
  width: fit-content;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  /* max-width: 0px; */
}

.datatable-filter
  .p-datatable.p-datatable-item-list
  .p-datatable-tbody
  > tr
  > td {
  cursor: auto;
  /* width: fit-content;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; */
}

.datatable-filter
  .p-datatable.p-datatable-item-list
  .p-dropdown-label:not(.p-placeholder) {
  text-transform: uppercase;
}
.customPrimeTable .p-toolbar {
  justify-content: flex-end;
}
.datatable-filter
  .p-datatable-item-list
  .p-datatable-tbody
  > tr
  > td
  .p-column-title {
  display: none;
}
.p-paginator .p-paginator-pages .p-paginator-page.p-highlight,
.p-paginator .p-paginator-pages .p-paginator-page {
  border-radius: 100px;
}
.customPrimeTable .p-datatable-header {
  padding: 0;
}
.customPrimeTable .p-datatable-header .table-header {
  display: flex;
  align-items: center;
  justify-content: start;
  position: relative;
}
.customPrimeTable .p-datatable-wrapper button + button {
  margin-left: 15px;
}
/* table-css */
.ncustom-table .p-datatable {
  border: 1px solid #deebfd;
  max-width: 100%;
  background: #fff;
  box-shadow: 0 0 15px 0 rgb(35 48 68 / 14%) !important;
  border-radius: 8px;
  overflow: hidden;
}
.ncustom-table .p-datatable .p-datatable-header {
  padding: 15px;
  background: #f7faff;
  border-bottom: 0;
  background-color: #283042;
}
.p-datatable .p-sortable-column .p-sortable-column-icon {
  color: #fff !important;
  font-size: 10px;
  position: relative;
  top: 1px;
}
.ncustom-table .p-datatable .p-datatable-header h5 {
  margin: 0;
  font-size: 22px;
  color: #17202d;
}
.ncustom-table
  .p-datatable
  .p-datatable-scrollable-wrapper
  .p-datatable-scrollable-header-box {
  margin-right: 0 !important;
}
.ncustom-table .p-datatable .p-datatable-scrollable-wrapper thead th {
  background: #233044;
  color: #fff;
  /* padding: 16px 15px; */
  font-size: 14px;
  background-color: #485970;
  /* height: 52px !important; */
}
.ncustom-table .p-datatable .p-datatable-tbody td {
  padding: 15px 15px;
  font-size: 14px;
  background: transparent;
  color: #233044;
  height: 65px !important;
}
.ncustom-table .p-datatable .p-datatable-tbody tr {
  background: #f4faff;
}
.ncustom-table .p-datatable .p-datatable-tbody tr:nth-child(odd) {
  background: #fff;
}
.ncustom-table .p-datatable .p-datatable-tbody td .actionbody {
  display: flex;
  justify-content: center;
}
.ncustom-table .p-datatable .p-datatable-scrollable-wrapper thead th:hover {
  background: #485970 !important;
  color: #fff !important;
}
.ncustom-table
  .p-datatable
  .p-datatable-tbody
  td
  .actionbody
  .MuiButtonBase-root {
  padding: 0;
  margin: 0 8px;
}
.customPrimeTable .p-datatable-header .table-header .p-input-icon-left input {
  width: 100%;
  background: #fff;
  padding: 8px 15px;
  border-radius: 4px;
  border: 1px solid #deebfd;
  min-height: 42px;
  padding-left: 50px;
}
.customPrimeTable
  .p-datatable-header
  .table-header
  .p-input-icon-left
  input:focus {
  box-shadow: none;
  outline: 0;
}
.customPrimeTable .p-datatable-header .table-header .p-input-icon-left i {
  left: 15px;
  font-size: 22px;
  top: 18px;
  color: #233044;
}
.projectTable.ncustom-table .p-datatable {
  border: 0;
  border-radius: 0 0 8px 8px;
}
/* table-css-over */
@media screen and (max-width: 40em) {
  .datatable-filter
    .p-datatable.p-datatable-item-list
    .p-datatable-thead
    > tr
    > th,
  .datatable-filter
    .p-datatable.p-datatable-item-list
    .p-datatable-tfoot
    > tr
    > td {
    display: none !important;
  }
  .p-button.p-button-outlined {
    display: none;
  }

  .datatable-filter .p-datatable.p-datatable-item-list .p-datatable-tbody > tr {
    border-bottom: 1.5px solid #a6d5fa;
  }

  .datatable-filter
    .p-datatable.p-datatable-item-list
    .p-datatable-tbody
    > tr
    > td {
    text-align: left;
    display: block;
    border: 0 none !important;
    width: 100% !important;
    float: left;
    clear: left;
    border: 0 none;
  }

  .datatable-filter
    .p-datatable.p-datatable-item-list
    .p-datatable-tbody
    > tr
    > td
    .p-column-title {
    padding: 0.4rem;
    min-width: 30%;
    display: inline-block;
    margin: -0.4rem 1rem -0.4rem -0.4rem;
    font-weight: bold;
  }
}

.myapprovals .p-button.p-component.p-button-outlined {
  display: none;
}


/* Custom CSS for Prime React Table */

.customPrimeTable tr td a, .customPrimeTable tr td button {
  padding-top: 0;
  padding-bottom:0;
}

.customPrimeTable .p-datatable-scrollable-body::-webkit-scrollbar {
  height: 10px;
}

.customPrimeTable .p-datatable-scrollable-body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 12px;
}

.customPrimeTable .p-datatable-scrollable-body::-webkit-scrollbar-thumb {
  border-radius: 12px;
  box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
}

.customPrimeTable .p-datatable-frozen-view .p-datatable-emptymessage td {
  visibility: hidden;
}

@media screen and (min-width: 768px) {
  .customPrimeTable .p-datatable-unfrozen-view .p-datatable-emptymessage td {
    padding-left: 30%;
  }
}